<template>
  <div>
    <h4>Change My Password</h4>
    <form @submit.prevent="changePassword">
      <div class="uk-margin">
        <label>Enter your current password</label>
        <input
          type="password"
          placeholder="Current Password"
          v-model="password.current"
          class="uk-input"
          required
        />
      </div>
      <div class="uk-margin">
        <label>Select a new password</label>
        <input
          type="password"
          placeholder="New Password"
          v-model="password.password"
          class="uk-input"
          required
        />
      </div>
      <div class="uk-margin">
        <label>Confirm your new password</label>
        <input
          type="password"
          placeholder="Confirm Password"
          v-model="password.password_confirmation"
          class="uk-input"
          required
        />
      </div>
      <div class="uk-margin">
        <button class="uk-button uk-button-primary">Update Password</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: {},
    };
  },

  methods: {
    changePassword() {
      this.$axios
        .post("/api/v1/guest/my-account/security", this.password)
        .then(() => {
          this.$toasted.show("Your account has been updated", {
            duration: 5000,
          });
        })
        .catch(() => {
          this.$toasted.show("Invalid current password", { duration: 10000 });
        });
    },
  },
};
</script>
